<template>
  <div>
    <d-button
      :text="selectedModel ? 'admin.duplicate.block-price-category.without-model' : 'admin.duplicate.block-price-category.with-model'"
      class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
      @on:button-click="scrollToCategoryList"
    />

    <block-price-category-list
      v-if="selectedModel || getSelectedBlockPriceCategory"
    />

    <div v-if="getSelectedBlockPriceCategory" class="border-bottom-grey mt-4 mb-4"/>

    <block-price-category-list-duplicate
      v-show="!selectedModel || getSelectedBlockPriceCategory"
      id="blockPriceCategoryListDuplicateId"
    />
  </div>
</template>
<script>
export default {
  components: {
    BlockPriceCategoryList:()=>import('./BlockPriceCategoryList'),
    BlockPriceCategoryListDuplicate:()=>import('./BlockPriceCategoryListDuplicate'),
  },
  data: () => ({
    duplicationModel: false,
    search: '',
    withModel: false,
    selectedModel: false,
  }),
  computed: {
    getSelectedBlockPriceCategory() {
      return this.$store.getters['blockPriceCategory/getSelectedBlockPriceCategoryModel'];
    }
  },
  methods: {
    scrollToCategoryList() {
      this.selectedModel = !this.selectedModel;

      this.$nextTick(() => {
        this.$store.commit('blockPriceCategory/setSelectedBlockPriceCategoryModel', null);
        setTimeout(() => {
          document.getElementById('blockPriceCategoryListDuplicateId').scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 300)
        ;
      });
    },
  },
  mounted() {
    this.$store.commit("blockPriceCategory/setCheckedBlockPriceCategoryList", [])
    this.$store.commit("blockPriceCategory/setSelectedBlockPriceCategoryModel", null)
  }
}
</script>
